import { Row, Col, Menu } from "antd";
import { useState } from "react";
import styles from './styles.module.css'
import Project2023 from "../Project2023/Project2023";
import Project2024 from "../Project2024/Project2024";
import { motion } from 'framer-motion';

export default function Projects() {


    const [year, setYear] = useState('2023');
    const [target, setTarget] = useState('0');

    const items = [
        {
            key: '2024',
            label: '2024',
            children: [
                { key: '0', label: 'FreshCheck' },
                { key: '1', label: 'Dyson Student Management System' }
            ],
        },
        {
            key: '2023',
            label: '2023',
            children: [
                { key: '0', label: 'ifindpal - find people of the same interest' },
                { key: '1', label: 'saleswift - mobile app for second-hand furniture' },
                { key: '2', label: 'NEXWAVE - chatroom with socket.io' },
            ],
        }
    ];

    function onClick(e) {
        let arr = e.keyPath;
        console.log(arr);
        setYear(arr[1]);
        setTarget(arr[0]);
        console.log(arr[1]);
    }



    return (
        <>
            <Row>
                <Col>
                    <motion.div
                        initial={{ x: '-100vw', opacity: 0 }}  // 从左侧完全不可见的位置开始
                        animate={{ x: 0, opacity: 1 }}          // 动画效果到达目标位置
                        exit={{ x: '-100vw', opacity: 0 }}      // 退出动画效果
                        transition={{ duration: 1.5, ease: 'easeOut' }}  // 延长持续时间并使用缓动效果
                        className={styles.content}>
                        <div className={styles.menu}>
                            <Menu
                                onClick={onClick}
                                style={{ width: 256 }}
                                defaultSelectedKeys={['0']}
                                defaultOpenKeys={['sub1']}
                                mode="inline"
                                items={items}
                            />
                        </div>
                        <div className={styles.projects}>
                            {year === '2024' && <Project2024 target={target} />}
                            {year === '2023' && <Project2023 target={target} />}
                        </div>
                    </motion.div>

                </Col>
            </Row>
        </>
    )
}