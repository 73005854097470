
import styles from './styles.module.css';
import Tools1 from '../../../assets/img2024_8/tools1.png'
import Tools2 from '../../../assets/img2024_8/tools2.png'
import Cloud from '../../../assets/img2024_8/cloud_icon.png'
import Linux from '../../../assets/img2024_8/linux.png'
import csharp from '../../../assets/img2024_8/csharp.png'
import Net from '../../../assets/img2024_8/net.png'
import Communication from '../../../assets/img2024_8/communication.jpg'
import Teamwork from '../../../assets/img2024_8/teamwork.jpg'
import { Grid, Space } from 'antd';
import { motion } from 'framer-motion';

export default function Advantages() {
    return (
        <>
            <motion.div
                initial={{ x: '-100vw', opacity: 0 }}  // 从左侧完全不可见的位置开始
                animate={{ x: 0, opacity: 1 }}          // 动画效果到达目标位置
                exit={{ x: '-100vw', opacity: 0 }}      // 退出动画效果
                transition={{ duration: 1.5, ease: 'easeOut' }}  // 延长持续时间并使用缓动效果
                className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.title}> Full Stack Software Development Solutions </div>
                    <div className={styles.tools}>
                        Tools <i className='iconfont icon-xiangyoujiantou'></i>
                        <img src={Tools1} style={{ width: '30%' }} /> &nbsp;
                        <img src={csharp} style={{ width: '4%' }} /> &nbsp;
                        <img src={Net} style={{ width: '4%' }} /> &nbsp;
                        <img src={Tools2} style={{ width: '12%' }} />&nbsp;
                        <img src={Cloud} style={{ width: '5%' }} />&nbsp;
                        <img src={Linux} style={{ width: '4%' }} />
                    </div>
                    <br />
                    <div className={styles.solutions}>
                        · Software Engineering Aspects
                    </div>
                    <div className={styles.solutions}>
                        · Data Structures & Algorithms
                    </div>
                    <div className={styles.solutions}>
                        · Continuous Integration/Continuous Deployment
                    </div>
                    <div className={styles.solutions}>
                        · Security & Compliance
                    </div>
                    <div className={styles.solutions}>
                        · Cloud Solutions
                    </div>
                    <br />
                    <div className={styles.slogan}>
                        <h2>
                            <span>M</span><span>o</span><span>v</span><span>i</span><span>n</span><span>g</span>
                            <span> F</span><span>o</span><span>w</span><span>a</span><span>r</span><span>d</span>
                        </h2>
                        &nbsp;
                        <i style={{
                            width: '200%',
                            height: '2rem',
                        }} className='iconfont icon-24gf-forward'></i>

                    </div>

                </div>
                <div className={styles.right}>
                    <div>
                        <Space direction='vertical' size={10}>
                            <div className={styles.box}>
                                <img src={Teamwork} style={{ width: '100%', height: '100%' }} />
                            </div>
                            <div className={styles.box}>
                                <img src={Communication} style={{ width: '100%', height: '100%' }} />
                            </div>

                        </Space>
                    </div>
                </div>
            </motion.div >
        </>
    )
}