import { useEffect, useState, useRef } from 'react';
import { Space } from 'antd';
import styles from './styles.module.css';
import FreshCheckLogin from '../../../assets/img2024_8/freshCheck_login.png';
import Ifindpal from '../../../assets/img2024_8/Ifindpal.png';
import Homepage from '../../../assets/img2024_8/HomeFreshCheck.png';
import Checklist from '../../../assets/img2024_8/checklist.png';
import Dyson1 from '../../../assets/img2024_8/dyson1.jpg'
import Dyson2 from '../../../assets/img2024_8/dyson2.jpg'
import Dyson3 from '../../../assets/img2024_8/dyson3.jpg'
export default function Project2024({ target }) {
    const [key, setKey] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

    const itemRefs = {
        item1: useRef(null),
        item2: useRef(null)
    };

    const targetKeyMap = {
        0: 'item1',
        1: 'item2'
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 576);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const targetKey = targetKeyMap[target];
        if (targetKey && itemRefs[targetKey] && itemRefs[targetKey].current) {
            itemRefs[targetKey].current.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Invalid target key or ref is not set correctly.');
        }
    }, [target]);

    return (
        <>
            <div className={styles.container}>
                <div ref={itemRefs.item1} className={styles.item}>
                    <div className={styles.title}>
                        FreshCheck
                    </div>
                    <div className={styles.description}>
                        A Web App that Helps With Food Monitoring and Daily Checklist
                    </div>
                    <div className={styles.picbox}>
                        <div>
                            The video is not uploaded at the moment, but will be uploaded in December.
                        </div>
                        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
                            <img className={styles.pic} src={FreshCheckLogin} />
                            <img className={styles.pic} src={Homepage} />
                            <img className={styles.pic} src={Checklist} />
                        </Space>
                    </div>
                </div>

                <div ref={itemRefs.item2} className={`${styles.item} ${styles.itemButtom}`}>
                    <div className={styles.title}>
                        Dyson Student Management System - Dyson & Newcastle University Project
                    </div>
                    <div className={styles.description}>
                        We successfully developed and deployed the Dyson Student Management System (DSMS), an integrated platform tailored to the unique needs of the Dyson Institute of Engineering and Technology. Our goal was to create a comprehensive system that supports the Institute’s mission of combining rigorous academic programs with hands-on experience. The DSMS provides essential features for students, including access to program and module information, timetable management, coursework tracking, and direct communication with tutors. For staff, the system offers tools for building and modifying timetables, tracking student progress, and managing academic responsibilities. Both students and staff benefit from secure access and various accessibility options, ensuring a seamless experience.
                        <br />
                        <br />
                        The project earned distinction for its impact on streamlining the Institute’s operations. The DSMS has significantly improved the efficiency of managing academic and administrative tasks, enhancing the experience for all users. Dyson has praised the system as a new standard in educational technology, this reflected our team’s commitment to innovation and excellence in delivering solutions that meet the Institute’s evolving needs.
                    </div>
                    <div className={styles.picbox}>

                        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
                            <img className={styles.pic2} src={Dyson1} />
                            <img className={styles.pic2} src={Dyson3} />
                        </Space>
                    </div>
                </div>
            </div>
        </>
    );
}
